.fw-logo {
    width: 80px;
    display: flex;;
}

.footer-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
}

.footer-span {
    display: flex;
    justify-content: center;
    color: rgba(255, 255, 255, 0.4);
    font-size: 13px;
}