#discord {
    padding: 75px 0 0 0;
    background-color: #000000;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
}

.cv-btn {
    width: 110px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border:solid 2px #0F5DFC;
    border-radius: 10px;
    color: #0F5DFC;
}

.cv-btn:hover {
    background-color: #0F5DFC;
    transition: all ease 0.5s;
    color: #fff;
}

.video-container {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.video-container video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 1),
            rgba(0, 0, 0, 0.1),
            rgba(0, 0, 0, 0),
            rgba(0, 0, 0, 0.1),
            rgba(0, 0, 25, 1)
    );
}

.discord-content {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 0 5% 0 5%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    color: #ffffff;
}

.discord-title {
    position: relative;
}

.discord-text h2 {
    font-size: 2rem;
    color: #0F5DFC;
    font-weight: 400;
}

.discord-img img {
    width: 500px;
}

.discord-text {
    width: 50%;
    font-size: 1.2rem;
    color: #b4b4b4;
}

.discord-img {
    width: 50%;
    margin: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.discord-text a {
    margin: auto;
}

.discord-text h3 {
    color: #fff;
    font-size: 1.17em;

}

.discord-text ul {
    list-style-type: "🌊  ";
}

.discord-text ul li {
    margin: 8px 0;
}

.surfer.credit {
    position: absolute;
    bottom: 10px;
    right: 10px;
    color: rgba(255, 255, 255, 0.4);
    font-size: 12px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

@media screen and (max-width: 768px) {

    .discord-text h2 {
        font-size: 1.5rem;
    }

    .discord-text {
        font-size: 1rem;
    }

    .discord-content {
        padding: 0 5%;
    }

    .discord-text ul li {
        margin: 5px 0;
    }
}

@media screen and (max-width: 1200px) {
    .discord-img {
        display: none;
    }

    .discord-content {
        flex-direction: column;
        padding: 0 5%;
    }

    .discord-text {
        width: 100%;
        margin: 0;
    }
}
