#servers {
    padding: 75px 0 50px 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../../images/background_blur.jpg");
    box-shadow: 0 10px 15px 0 inset;
    min-height: 100vh;
}

#servers .s-heading {
    padding: 0 5% 0 5%;
}

.b-container {
    display: flex;
    justify-content: center;
    align-content: center;
}

.server-list h2 {
    font-size: 32px;
    color: #fff;
    text-align: center; /* Centers the text */
    margin-bottom: 15px;
}

.server-list h3 {
    font-size: 20px; /* Adjust this value as needed for the tick headings */
    color: #fff;
    margin-bottom: 10px;
}

.server-list {
    display: grid;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.server-details {
    flex-grow: 1;
    text-align: right;
}

.server {
    justify-content: space-between;
    width: auto;
    display: flex;
    align-items: flex-end;
    background-color: rgba(0, 0, 0, .536);
    border-radius: 10px;
    padding: 0 0;
    margin-bottom: 5px;
}

.server-elm {
    margin: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: 0.4s ease-in;
}

.server-name {
    margin-left: 10px;
}

.server-join {
    color: #FFFFFF;
    border: #0F5DFC 1px solid;
    background:  #0F5DFC;
    padding: 5px 10px;
    margin: 5px 5px 5px auto;
    transition: 0.4s ease-in;
    align-items: flex-end;
    right: 0;
    text-align: right;
}

.server-join:hover {
    background-color: #0F5DFC;
    color: #ffffff;
}

.copy-button {
    color: #0F5DFC;
    border: #0F5DFC 1px solid;
    padding: 5px 10px;
    margin: 5px 2px 5px auto;
    transition: 0.4s ease-in;
    align-items: flex-end;
    right: 0;
    text-align: right;
    font-size: 1rem;
    background: none;
    cursor: pointer;
}

.copy-button:hover {
    background-color: #0F5DFC;
    color: #ffffff;
}


.ip {
    text-align: right;
}

.server-elm p:last-child {
    text-align: right;
}

.server-list > h2 {
    margin: 8px 0 0 0;
}

@media screen and (max-width: 768px) {

    .server-join {
        display: none;
    }

    .ticks {
        display: none;
    }

    .server-name {
        font-size: 0.9rem;
    }

    .join-msg {
        display: none;
    }
    .copy-button {
        display: none;
    }

    .b-container {
        flex-direction: column;
    }

    .server {
        flex-direction: column;
        align-items: center;
        padding: 0 10px;
    }

    .server-list h2 {
        text-align: center;
    }

    .server-elm {
        margin: 5px 0;
    }
}
