nav .menu-btn,
.menu-icon {
    display: none;
}

.nav-elm {
    font-size: 1.2rem;
    display: inline-block;
    border-radius: 1px;
    text-decoration: none;
    color: #ffffff;
    background-image: linear-gradient(#0F5DFC, #0F5DFC);
    background-size: 0 5px, auto auto;
    background-repeat: no-repeat;
    background-position: center bottom;
    transition: all .3s ease-out;
    cursor: pointer;
}

.nav-item {
    margin: 0 auto;
}

.nav-elm:hover {
    background-size: 100% 2px, auto;
}

.nav-elm.active {
    background-size: 100% 2px, auto;
}

nav {
    width: 70%;
}

.menu {
    padding: 0;
}

@media (max-width: 1200px) {
    nav {
        width: 100%;
    }
}

@media (max-width: 768px) {

    .menu-container {
        width: 100%;
    }

    .menu-icon {
        display: block;
    }

    .menu {
        display: none;
        width: 100%;
        flex-direction: column;
    }

    .menu.open {
        display: flex;
        padding: 0;
        margin: 0;
        background: rgba(0, 0, 0, 0.8);
    }

    .nav-item {
        border-bottom: 0;
        padding: 8px 0;
        text-decoration: none;
    }
}

/*Hamburger*/
.hamburger {
    width: 2rem;
    height: 2rem;
    position: fixed;
    top: 15px;
    right: 20px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    z-index: 1000;
    display: none;
}

.hamburger div {
    width: 2rem;
    height: 0.25rem;
    background-color: #ffffff;
    border-radius: 10px;
    transition: all 0.3s linear;
}

@media (max-width: 768px) {
    .hamburger {
        display: flex;
    }

    .hamburger.open div:nth-child(1) {
        transform: rotate(45deg);
        position: relative;
        top: 11px;
    }

    .hamburger.open div:nth-child(2) {
        opacity: 0;
    }

    .hamburger.open div:nth-child(3) {
        transform: rotate(-45deg);
        position: relative;
        top: -11px;
    }
}

.nav-bg {
    background-color: #0f0f0f;
}

nav ul {
    display: flex;
}

nav ul li a {
    font-family: 'Jacinto Sans';
    height: 40px;
    line-height: 43px;
    display: flex;
    font-size: 0.8rem;
    text-transform: uppercase;
    /* font-weight: 800; */
    color: #ffffff;
    letter-spacing: 1px;
    border-radius: 3px;
    transition: 0.2s ease-in-out;
}

.nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: 5px 10px 30px rgba(0, 0, 0, 0.336);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1;
    flex-direction: column;
}

.menu-container {
    cursor: pointer;
}

.menu-icon {
    display: none;
}