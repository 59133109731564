#donate {
    padding: 75px 0 50px 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 30px;
    background-image: url("../../images/background_hydra_blur.jpg");
    box-shadow: 0 10px 15px 0 inset;
    min-height: 100vh;
}

.donate {
    padding-top: 20px;
    background-color: rgba(0, 0, 0, 0.536);
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
    color: #fff;
    border-radius: 5px;
}

.donate-payment h3 {
    margin-bottom: 0;
}
.donate-submit button {
    margin: 0;
}

.donate-black p {
    font-size: 1.2rem;
    margin: 28px 5%;
}

@media screen and (max-width: 768px) {
    .donate {
        padding: 10px;
    }

    .donate-black p {
        font-size: 1rem;
    }
}
.denomination {
    float: left;
    width: 33%;
    text-align: center;
    padding: 13px 0;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.436);
    margin: 0 1px 1px 0;
    transition: background-color 0.2s ease;
}

.denomination label {
    font-weight: 600;
    cursor: pointer;
}

.denomination input {
    left: -10001px;
    position: absolute;
}

.denomination-other {
    width: 99.8%;
}

.denomination-other input {
    position: relative;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    width: inherit;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.436);
    border: none;
    padding: 13px 0;
    transition: background-color 0.2s ease;
}

.denomination-other input:before {
    position: absolute;
    left: 6px;
    top: 2px;
    content: '$';
}

.donate-amount .selected,
.denomination-other input.selected,
.donate-amount .denomination:hover {
    background-color: #0F5DFC;
    border: 0;
}

.donate-black h2 {
    font-family: 'Oswald', sans-serif;
    color: #fff;
    margin-bottom: 30px;
}

.donate-black h2 span {
    display: block;
    float: left;
    font-size: 16px;
    padding-top: 7px;
    margin-right: 20px;
    text-align: center;
    width: 30px;
    height: 25px;
    background-color: #0F5DFC;
    border-radius: 50%;
}

.donate-type label,
.donate-payment label {
    font-size: 14px;
    font-weight: 300;
    margin-right: 30px;
}

.donate-blue {
    background-color: rgba(0, 0, 0, 0.336);
    padding: 25px;
}

.donate-black {
}

.donate-submit button {
    font-family: 'Oswald', sans-serif;
    width: 100%;
    background-color: #0F5DFC;
    border: none;
    color: #fff;
    font-size: 20px;
    line-height: 20px;
    padding: 14px 0;
    margin: 30px 0;
    text-transform: uppercase;
    cursor: pointer;
    border-radius: 10px;
}

.donate-submit small {
    color: rgba(255, 255, 255, 0.4);
    font-size: 13px;
}

::-webkit-input-placeholder {
    color: #fff;
    font-size: 14px;
    font-weight: 300;
}

:-moz-placeholder {
    color: #fff;
    font-weight: 300;
}

::-moz-placeholder {
    color: #fff;
    font-weight: 300;
}

:-ms-input-placeholder {
    font-weight: 300;
    color: #fff;
}

*:focus {
    outline: none;
}
