@font-face {
    font-family: "Jacinto Sans"; 
    src: local("Jacinto"),
      url("./font/Jacinto Sans.otf") format("opentype");
  }
  @font-face {
    font-family: "zekton rg"; 
    src: local("zekton rg"),
      url("./font/zekton rg.otf") format("opentype");
  }


body {
    margin: 0;
    padding: 0;
    background-color: #000000;
    font-family: 'zekton rg';
}

* {
    box-sizing: border-box;
    border: #0f0f0f;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
}

button {
    outline: none;
    border: none;
}

input {
    outline: none;
    border: none;
}

.s-heading h1 {
    color: #FFFFFF;
    font-size: 3rem;
    margin: 0;
    padding: 0;

}

.s-heading p {
    color: #ffffff;
    font-size: 1.2rem;
    margin: 0;
    padding: 0;
}

.s-heading {
    text-align: center;
    margin: 20px 0;
}

@media screen and (max-width: 768px) {
    .s-heading h1 {
        font-size: 2rem;
    }

    .s-heading p {
        font-size: 1rem;
    }
}

.highlight {
    color: #fff;
    font-weight: 600;
}
