#features {
    padding: 75px 0 0 0;
    min-height: 100vh;

}

#features-body {
    background-color: #000000;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 50px 5% 0 5%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
}

.features-text {
    color: #fff;
    list-style: circle;
}

.features-text h2 {
    font-size: 2rem;
    color: #05e7ff;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

.features-text p {
    font-size: 1.2rem;
    color: #b4b4b4;
}

.features-text button:hover {
    background-color: #ffffff;
    transition: all ease 0.3s;
    color: #05e7ff;
}

.features-model {
    margin-right: 50px;
}

.features-model img {
    width: 500px;
}

@media screen and (max-width: 1200px) {
    #features-body {
        padding: 0 5% 0 5%;
    }

    .features-text h2 {
        font-size: 1.5rem;
    }

    .features-text p {
        font-size: 1rem;
    }

    .features-model img {
        width: 300px;
    }
}

@media screen and (max-width: 900px) {
    #features-body {
        flex-direction: column;
    }

    .features-text {
        width: 100%;
    }

    .features-text h2 {
        font-size: 1.5rem;
    }

    .features-text p {
        font-size: 1rem;
    }

    .features-model {
        margin: 0;
    }

}