.container-style {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
}

.description {
    position: absolute;
    top: 20%;
    left: 20%;
    right: 20%;
    background-color: rgba(0,0,0,.536);
    border-radius: 5px;
    padding: 0 50px;
    text-align: center;
    height: 500px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.description h1 {
    font-size: 46px;
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 6px;
}

.description p {
    font-size: 22px;
    text-align: left;
    text-shadow: 0 0 10px white;
}

.imageSlider {
    background-size: cover;
    background-position: center;
    height: 100vh;
    transition: background-image 0.5s ease-in-out;
}

.carousel-bullets {
    display: flex;
    justify-content: center;
    padding: 1em;
}

.carousel-bullets span {
    width: 15px;
    height: 15px;
    margin: 0 0.5em;
    background-color: #ccc;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s;
}

.carousel-bullets span.active {
    background-color: #0F5DFC;
}

.heroic-text {
    background: linear-gradient(45deg, #C2F2CA, #82E8D2, #97A5FF, #0013D1, #2A2191);
    background-size: 150%;
    animation: gradient 2.5s ease infinite;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@keyframes gradient {
    0%,
    to {
        background-position: 0 50%
    }
    50% {
        background-position: 100% 50%
    }
}

@media screen and (max-width: 1200px) {

    .description {
        left: 2%;
        right: 2%;
        height: 600px;
    }
}

@media screen and (max-width: 768px) {
    .description {
        top: 60px;
        left: 2%;
        right: 2%;
        bottom: 10%;
        padding: 20px;
        height: 600px;
        margin-bottom: 2%;
    }

    .description h1 {
        font-size: 32px;
    }

    .description p {
        font-size: 18px;
    }

    .imageSlider {
        height: 700px;
    }
}

.pause-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    color: rgba(255, 255, 255, 0.2);
    font-size: 2em;
}
